<footer class="footer ">
  <div class="container">
<!--    <nav class="pull-left">-->
<!--      <ul>-->

<!--        <li>-->
<!--          <a href="http://www.disciplesoft.co.za">-->
<!--            Licenses-->
<!--          </a>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </nav>-->
<!--    <div class="copyright pull-right">-->
<!--      &copy;-->
<!--      {{test | date: 'yyyy'}}, made with <i class="material-icons">favorite</i> by-->
<!--      <a href="http://www.disciplesoft.co.za" target="_blank">Disciplesoft</a> -->
<!--    </div>-->
  </div>
</footer>
