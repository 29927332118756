import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SwalService {

  constructor(private router:Router) {

  }


  show(message,type){

    return Swal.fire({
      title: this.capitalize(type),
        text: message,
    //  type: type,
   //   confirmButtonClass: "btn btn-info",
      buttonsStyling: false,
        customClass: {
//
            confirmButton: 'btn btn-success',

        }
    })
  }


    showImage(img) {

        return Swal.fire({
            title: 'Image',
            imageUrl: img,
            buttonsStyling: false,
            customClass: {
//
                confirmButton: 'btn btn-success',

            }
        })
    }


    confirm(msg) {
        Swal.fire({
            title: 'Info',
            text: msg,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Download Pdf',
            denyButtonText: 'Submit non compliance',
            customClass: {
            actions: 'my-actions',
            cancelButton: 'btn btn-warning',
            confirmButton: 'btn btn-success',
            denyButton:'btn btn-primary'
        }
    }).then((result) => {
        console.log(result)
        if (result.isConfirmed) {
            window.open('https://firebasestorage.googleapis.com/v0/b/waccsa-e8589.appspot.com/o/Notice%20of%20non%20compliant.pdf?alt=media&token=b65dc360-aa0f-4c8e-abed-e043fd132114','_blank')
             Swal.fire('PDF opened', '', 'info')

        } else if (result.isDenied) {
            this.router.navigate(['/noc'])

        } else if (result.dismiss) {
            //   Swal.fire('Canceled', '', 'info')

        }
        })

    }


    ifCon(message, type) {

        return Swal.fire({
            title: 'Warning',
            text: message,
            //  type: type,
            confirmButtonText: 'Yes',
            showCancelButton: true,
            showConfirmButton: true,
            customClass: {
                actions: 'my-actions',
                cancelButton: 'btn btn-danger',
                confirmButton: 'btn btn-success',
                denyButton: 'btn btn-warning'
            }

        })
    }

    del(message, type) {

        return Swal.fire({
            title: 'Warning',
            text: message,
            //  type: type,
            confirmButtonText: 'Delete',
            showCancelButton: true,
            showConfirmButton: true,
            customClass: {
                      actions: 'my-actions',
                cancelButton: 'btn btn-danger',
                confirmButton: 'btn btn-success',
                denyButton:'btn btn-warning'
                 }
           
        })
    }


  success(){
    return       Swal.fire({
      title: 'Payment received',
      text: 'Your payment has been received Thank you',
      //type: 'success',
      //confirmButtonClass: "btn btn-success",

      buttonsStyling: false,
        customClass: {
//
            confirmButton: 'btn btn-primary',

        }
    })

  }


    payD (amount){


   return Swal.fire({
      title: 'Info',
      text: 'Please select payment option of R'+amount,
     // type: 'error',
      showCancelButton: true,
      // cancelButtonClass:"btn btn-danger",
      confirmButtonText: 'Pay now',
       showDenyButton:true,
    //  confirmButtonClass: "btn btn-success",
      buttonsStyling: false,
       denyButtonText: 'Use wallet funds',

       customClass: {
           actions: 'my-actions',
           cancelButton: 'btn btn-danger',
           confirmButton: 'btn btn-success',
           denyButton:'btn btn-warning'

       }
    })
  }



    pay (amount){


        return Swal.fire({
            title: 'Info',
            text: '',
          //  type: 'error',
            showCancelButton: true,
           // cancelButtonClass:"btn btn-danger",
            confirmButtonText: 'Pay',
          ////  confirmButtonClass: "btn btn-success",
            buttonsStyling: false,
            showDenyButton:true,
            denyButtonText: 'No',
                  customClass: {
                      actions: 'my-actions',
                      cancelButton: 'btn btn-danger',
                      confirmButton: 'btn btn-success',
                      denyButton: 'btn btn-success',
                  }
        })
    }


    payWallet (amount){


        return Swal.fire({
            title: 'Info',
            text: 'You need to pay  R'+amount+' to download this submissions',
          ////  type: 'error',
            showCancelButton: true,
          //  cancelButtonClass:"btn btn-danger",
            confirmButtonText: 'Pay',
          //  confirmButtonClass: "btn btn-success",
            buttonsStyling: false
        })
    }

  alert(msg,status){
      // @ts-ignore
      return Swal.fire({
          title: this.capitalize(status),
          text: msg,
          type: status,
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false
      })
  }


     capitalize(s)
    {
        return s[0].toUpperCase() + s.slice(1);
    }
}
