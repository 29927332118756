
<div [lyDisplay]="'flex'">
    <button ly-button color="primary" (click)="_fileInput.click()">
        <ly-icon>image</ly-icon>
        <span>Select File</span>
    </button>
    <span [lyFlex]="1"></span>
    <!-- Hidden input -->
    <input #_fileInput type="file" (change)="cropper.selectInputEvent($event)" accept="image/*" hidden>
    <button raised ly-button bg="accent" *ngIf="croppedImage">Upload</button>
</div>
<ly-img-cropper
        [config]="myConfig"
        [(scale)]="scale"
        (minScale)="minScale = $event"
        (ready)="ready = true"
        (cleaned)="ready = false"
        (cropped)="onCropped($event)"
        (loaded)="onLoaded($event)"
        (error)="onError($event)"
>
    <span>Drag and drop image</span>
</ly-img-cropper>

<div *ngIf="ready" [className]="classes.sliderContainer">
    <ly-slider
            [thumbVisible]="false"
            [min]="minScale"
            [max]="1"
            [(ngModel)]="scale"
            (input)="scale = $event.value"
            step="0.000001"></ly-slider>
</div>

<button *ngIf="ready" color="accent" (click)="cropper.crop()" ly-button>
    <ly-icon>crop</ly-icon>crop
</button>

<div><img [class]="classes.cropResult" *ngIf="croppedImage" [src]="croppedImage"></div>
