<ngx-spinner fullScreen="true"
             bdColor="rgba(65,98,174,0.6)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate-multiple">
 <p style="font-size: 20px; color: white">Please wait....</p>
</ngx-spinner>
<nav class="navbar navbar-expand-lg bg-primary navbar-transparent navbar-absolute" color-on-scroll="500">
  <div  class="container">
    <div class="text-center" >
      <a class="navbar-brand d-none d-sm-none d-md-block" [routerLink]="['/dashboard']">

        <img style="height: 55px" src="/assets/img/logo2.png"/>

      </a>

    </div>
    <button hidden mat-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
   
  </div>
</nav>
  <router-outlet></router-outlet>
