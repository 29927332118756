import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {first} from 'rxjs/operators';
import {AuthService} from './services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadsService {
  public user: any;
    private uid: any;

  constructor(
      public afs:AngularFirestore,
      private d:AuthService,
  ) { }



  async getUid() {

    this.user =  await this.d.getUser()
    this.uid = this.user.uid
  }

  getDownloads() {

    return this.afs.collection('downloads', ref => ref.orderBy('date','desc').limit(400)).valueChanges({idField: "docid"}).pipe(first()).toPromise();

  }


  getDownLoad(id){
    return this.afs.collection('downloads').doc(id).valueChanges().pipe(first()).toPromise();
  }

}
