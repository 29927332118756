<form (ngSubmit)="onSubmit()" [formGroup]="form">

    <mat-dialog-content class="mat-typography" lyAlignContent="center">
        <p>Send Message</p>

        <mat-form-field appearance="fill">
            <mat-label>Type your message here</mat-label>
            <textarea formControlName="message" matInput rows="7"></textarea>


            <app-field-error-display [displayError]="isFieldValid(form, 'message')"
                                     errorMsg="Client contact number required">
            </app-field-error-display>
        </mat-form-field>

        <div>
            <div class="row">
                <div *ngFor="let i of [1,1]" class="col-md-6 p-2">
                    <mat-form-field>
                        <ngx-mat-file-input (ngModelChange)="uploadFile($event,'images')"
                                            [accept]="' .png, .jpg , .jpeg'" formControlName="imagez"
                                            placeholder="Photo attachment"
                        >

                        </ngx-mat-file-input>
                        <mat-icon matSuffix>attach_file</mat-icon>


                    </mat-form-field>

                </div>

            </div>
        </div>
</mat-dialog-content>
<div class="mat-dialog-actions">
    <!--  <button mat-button (click)="onNoClick()">No Thanks</button>-->
    <button [mat-dialog-close]="form.value" cdkFocusInitial class="blue3 btn-block"
            mat-button type="submit">Send
    </button>
</div>
</form>
