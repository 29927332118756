import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {NotificationService} from '../services/notification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SwalService} from '../services/swal.service';
import {AngularFireStorage} from '@angular/fire/storage';
import {AngularFireAuth} from '@angular/fire/auth';
import {AuthService} from '../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subject, Subscription} from 'rxjs';
import Swal from 'sweetalert2';
import {DownloadsService} from '../downloads.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CocService} from '../services/coc.service';
import {HttpClient} from '@angular/common/http';
import {searchClient} from '../submissions/store.component';
import imageCompression from 'browser-image-compression';
import {finalize} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

declare let $: any;

@Component({
    selector: 'app-service-submissions',
    templateUrl: './downloads.component.html',
    styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit, OnDestroy {
    public uid: string;
    public subs = [];
    downloads: any;
    private geysers: any;
    dtOptions: any = {
        'ordering': false
    };
    private sub: Subscription;

    auditors: any = ['Marc',
        'Geoff',
        'Darrin',
        'Scott',
        'Nicholas'

    ];
    statuses: any = [
        'Refix',
        '1st Warning',
        '2nd Warning',
        '3rd Warning',
        '*Fixed / Approved',
        '*Suspended',
        'Approved',
        'Feedback'
    ]
    dtTrigger: Subject<any> = new Subject();
    mobile: boolean;
    private amount: any;
    public user: any;
    config = {
        indexName: 'downloads',
        searchClient,
    };

    message: any;
    pdfLink: any = null;

    // }
    filters: string = null;
    isAdmin: boolean;
    //     }
    searchQuery = null;

    openDialog(row): void {


        if (!row.status || !row.auditor) {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Please Select Status or Auditor',
                showConfirmButton: false,
                timer: 1500
            }).then(() => {

                this.spinner.hide()

            })
        } else {


            const dialogRef = this.dialog.open(EmailDialog, {
                //  width: '1950px',
                data: {message: this.message,},
            });

            dialogRef.afterClosed().subscribe(async text => {

                this.message = text.message;



                if (text) {
                    this.spinner.show().then()
                    if (row.status == '1st Warning' || 'Refix' || row.status == '2nd Warning' || row.status == '3rd Warning') {

                        await this.afs.collection('submissions').doc(row.objectID).update({refix: 1}).then()
                        // await this.afs.collection('downloads').doc(row.id).update({refix: true}).then()


                    } else if (row.status == '*Fixed / Approved' || row.status == 'Approved') {
                        await this.afs.collection('submissions').doc(row.objectID).update({refix: 2}).then()
                    } else if (row.status == '*Suspended') {

                        await this.afs.collection('submissions').doc(row.objectID).update({refix: 3}).then()
                    }

                    this.getCoc(row.objectID).then((coc) => {
                        this.pdfLink = coc;
                    })
                    this.changeStat(row, text).then()


                }
            });
        }

    }

    constructor(public dialog: MatDialog,
                public afs: AngularFirestore,
                public toast: NotificationService,
                public spinner: NgxSpinnerService,
                private http: HttpClient,
                public s: SwalService,
                private download: DownloadsService,
                private coc: CocService,
                public router: Router,
                private route: ActivatedRoute,
                public a: AuthService,
                public storage: AngularFireStorage,
                public afAuth: AngularFireAuth) {


    }


    nav(sub: any) {


        this.router.navigate([sub.type + '/' + sub.objectID])
    }

    ngOnInit() {


        this.isAdmin = localStorage.getItem('user_type') === 'admin';
        window.onresize = () => this.mobile = window.innerWidth <= 991;

        this.getUid().then()

        this.getDownl();
    }


    stringify(row: any) {
        return JSON.stringify(row)
    }


    // async changeStatus(row: any) {
    //     // @ts-ignore
    //     const { value: text } = await Swal.fire({
    //         input: 'textarea',
    //         title: 'Send Message',
    //         inputPlaceholder: 'Type your message here...',
    //
    //         inputValidator: (value) => {
    //             if (!value) {
    //                 return 'Please enter a message!'
    //             }
    //         },
    //         showCancelButton: true
    //     })

    //     if (text) {
    //          this.spinner.show().then()
    //         // if (row.status == '*Fixed / Approved'){
    //         //
    //         //    // await this.afs.collection('submissions').doc(row.id).update({refix:true}).then()
    //         //    //  await this.afs.collection('downloads').doc(row.id).update({refix:true}).then()
    //         //
    //         //
    //         // }
    //         this.changeStat(row, text).then()
    //
    //

    ngOnDestroy(): void {
        // this.sub.unsubscribe()

    }

    async getDownloads() {

        if (this.isAdmin) {
            this.searchQuery = true
            this.route.queryParams.subscribe((params: any) => {
                if (params.org) {
                    this.filters = 'org:QRbhTs1OKf27IuB860Og'
                } else {
                    this.filters = ''
                }
            })
        }
        {
            if (localStorage.getItem('user_type') === 'province') {
                this.filters = `province:"${this.user.province}"`


            }

            if (localStorage.getItem('user_type') === 'municipal') {
                console.log(this.user)
                this.filters = `municipal:"${this.user.municipal}"`


            } else {
                this.filters = ''

            }

        }

    }

    async change(row) {


        if (row.status == 'Approved') {
            this.spinner.show().then()
            await this.afs.collection('downloads').doc(row.objectID).update({
                status: row.status,
                auditor: row.auditor || null
            })
            this.spinner.hide().then()

        }

        if (row.status == 'Feedback') {
            const dialogRef = this.dialog.open(EmailDialog, {
                //  width: '1950px',
                data: {message: this.message,},
            });

            dialogRef.afterClosed().subscribe(async text => {

                console.log(text)
                this.message = text.message;

                if (text) {
                    this.getCoc(row.objectID).then(async r => {
                        await this.spinner.show()
                        this.pdfLink = r;
                        await this.afs.collection('downloads').doc(row.objectID).update({
                            feedback: 'Sent',

                        })
                        if (text) {
                            this.spinner.show().then()


                            let body = `<h4 style='font-size: 19px;'>WACC COC #  ${row.count} Audit  FEEDBACK</h4>
        
                              

        <br />
        
        <p >${text.message}  <br />
        <br />
        
         <p>For any auditing enquires please contact <br /><a href='mailto:audits@waccsa.co.za'> audits@waccsa.co.za</a> with your COC attached and your COC number as the subject.</p>
                <p>Kind regards,<br />WACC Auditing Team  <a href='https://waccsa.co.za'> https://waccsa.co.za</a></p>
                
             
        `
                            let attachments: any = text.images
                            attachments.push({
                                path: this.pdfLink,
                                filename: 'Coc.pdf'
                            })
                            let data = {
                                body: body.toString(),
                                cc: 'audits@waccsa.co.za',
                                email: row.email,
                                subject: 'Wacc C0C Audit Feedback',
                                attachments: attachments
                            }
                            this.a.sendMail(data, 'sendMail').subscribe(() => {
                                this.s.show('Feedback Sent', 'success')
                                this.spinner.hide()

                                this.navR()

                            }, error => {
                                this.spinner.hide()

                            });
                            this.s.show('Feedback Sent', 'success')


                        }
                    })
                }
            })
        }
    }


    async getCoc(id) {

        const coc: any = await this.coc.getSingle(id)


        return coc.pdfLink
    }

    async changeStat(row: any, text) {


        await this.afs.collection('downloads').doc(row.objectID).update(row)

        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Changed Status and message sent to the plumber',
            showConfirmButton: false,
            timer: 1500
        }).then(() => {

            setTimeout(() => {
                this.spinner.hide()
                location.reload()

            }, 3000)

        })


        let body = `<h4 style='font-size: 19px;'>WACC COC #  ${row.count}</h4>
        <p style='font-size: 19px;''> ${row.auditor} has been assigned to audit a COC </p>
         <br />
        <br />
      
         <p>${text.message}  <br />
       
        `

        let attachments: any = text.images
        attachments.push({
            path: this.pdfLink,
            filename: 'Coc.pdf'
        })

        let data = {
            body: body.toString(),
            email: 'audits@waccsa.co.za',
            subject: 'Wacc COC ' + row.count + ' Audit',
            attachments: attachments
        }


        let body2 = `<h4 style='font-size: 19px;'>WACC COC #  ${row.count}</h4>
        <p style='font-size: 19px;'>Status Changed to ${row.status} </p> <br />
         <p style='font-size: 19px;'>Good day </p>
        
        <br />
        
        <p >${text}  <br />
        <br />
        
         <p>For any auditing enquires please contact <br /><a href='mailto:audits@waccsa.co.za'> audits@waccsa.co.za</a> with your COC attached and your COC number as the subject.</p>
                <p>Kind regards,<br />WACC Auditing Team  <a href='https://waccsa.co.za'> https://waccsa.co.za</a></p>
                
             
        `


        // this.images.push(this.pdfLink)
        let data2 = {
            body: body2.toString(),
            email: row.email,
            attachment: this.pdfLink,
            cc: 'audits@waccsa.co.za',
            subject: 'Wacc COC ' + row.count + ' Audit',
        }
        this.a.sendMail(data, 'sendMail').subscribe(() => {
            this.s.show('Success', 'success')
            this.navR()

        });

        this.a.sendMail(data2, 'sendMail').subscribe(() => {
            this.spinner.hide()

        });

    }


    getDownl() {
        this.http.get('https://us-central1-waccsa-e8589.cloudfunctions.net/getTotalDownloads').subscribe((res: any) => {
            this.downloads = res.total
        })
    }

    async getUid() {

        this.user = await this.a.getUser()
        this.getDownloads().then()

        this.uid = this.user.uid
    }

    navG(s: string) {
        window.open(s, '_blank', 'width=1000, height=800')

    }

    navR() {
        location.reload()
    }


}


export interface DialogData {
    message: any;
    formImages: any

}


@Component({
    selector: 'email-dialog',
    templateUrl: 'email-dialog.html',
})
export class EmailDialog {
    form: FormGroup;
    private images = [];
    private uploadPercent: Observable<number | undefined>;
    private formImages = [];

    constructor(
        public dialogRef: MatDialogRef<EmailDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public storage: AngularFireStorage,
        public spinner: NgxSpinnerService,
        public formBuilder: FormBuilder,

    ) {
        this.form = this.formBuilder.group({
            // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.

            message: [null, Validators.required],
            images: [''],
            imagez: ['']

        })

    }

    onNoClick(): void {
        this.dialogRef.close();
    }


    async uploadFile(event, img) {
        // take the filename from the file

        try {
            if (event.files) {
                const file = event.files[0];
                const options = {
                    maxSizeMB: 1,

                    useWebWorker: true
                }
                try {
                    const compressedFile = await imageCompression(file, options);


                    await this.uploadToServer(compressedFile, img); // write your own logic
                } catch (error) {
                    this.formImages = null;


                    console.log(error);
                }


            }


        } catch (e) {

        }

    }


    async uploadToServer(file, img) {
        this.spinner.show();
        const filePath = Date.now().toString() + '.png';
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, file);

        // observe percentage changes
        this.uploadPercent = task.percentageChanges();

        task.snapshotChanges().pipe(
            finalize(async () => {
                const downloadURL = await fileRef.getDownloadURL().toPromise();
                this.spinner.hide();

                const newImage = {filename: `Email  Attachment (${this.images.length + 1}).png`, path: downloadURL};

                // Check if the image already exists in the array, if not, add it
                const existingIndex = this.images.findIndex(image => image.path === downloadURL);
                if (existingIndex === -1) {
                    this.images.push(newImage);
                } else {
                    // Remove the existing image and update the array
                    this.images.splice(existingIndex, 1, newImage);
                }

                // Update form value with the updated images array
                this.form.patchValue({'images': this.images});
            })
        ).subscribe();
    }

    isFieldValid(form: FormGroup, field: string) {
        return !form.get(field).valid && form.get(field).touched;
    }

    onSubmit() {
        if (this.form.invalid) {
        }
    }
}
