import {Routes} from '@angular/router';

import {AdminLayoutComponent} from './layouts/admin/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth/auth-layout.component';
import {RoleGuardService} from './services/role-guard.service';
import {AddUsersRoleService} from './add-users-role.service';
import {AngularFireAuthGuard, redirectUnauthorizedTo} from '@angular/fire/auth-guard';


const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'submit',
        pathMatch: 'full',


    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [


            {
                path: 'components',
                loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule)
            },

            {
                path: '',
                loadChildren: () => import('./inbox/inbox.module').then(m => m.InboxModule)
            },

            {
                path: 'forms',
                loadChildren: () => import('./forms/forms.module').then(m => m.Forms)
            }, {
                path: 'tables',
                loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule)
            }, {
                path: 'widgets',
                loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule)
            },


            {
                path: '',
                loadChildren: () => import('./approve-submissions/approve-submissions.module').then(m => m.ApproveSubmissionsModule),
                canActivate: [AddUsersRoleService]

            },

            {
                path: '',
                loadChildren: () => import('./approve-service-submissions/approve-service-submissions.module').then(m => m.ApproveServiceSubmissionsModule),
                canActivate: [AddUsersRoleService]
            },


            {
                path: '',
                loadChildren: () => import('./service-submissions/service-submissions.module').then(m => m.ServiceSubmissionsModule),

            },

            {
                path: '',
                loadChildren: () => import('./submissions_by_source/submissions_by_source.module').then(m => m.SubmissionsBySourceModule),

            },


            {
                path: '',

                loadChildren: () => import('./add-listing/add-listing.module').then(m => m.AddListingModule),
                //canActivate: [RoleGuardService]
            },

            {
                path: '',

                loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule),

            },

            {
                path: '',

                loadChildren: () => import('./downloads/downloads.module').then(m => m.DownloadsModule),
                canActivate: [RoleGuardService]
            },


            {
                path: '',

                loadChildren: () => import('./coc/coc.module').then(m => m.CocModule),
            },

            {
                path: '',
                loadChildren: () => import('./submissions/store.module').then(m => m.StoreModule),

            },


            {
                path: '',
                loadChildren: () => import('./nuflow/nuflow.module').then(m => m.NuflowModule),

            },


            {
                path: '',
                loadChildren: () => import('./noc-submissions/noc-submissions.module').then(m => m.NocSubmissionsModule),

            },

            {
                path: '',
                loadChildren: () => import('./sop-submissions/sop-submissions.module').then(m => m.SopSubmissionsModule),

            },
            {
                path: '',
                loadChildren: () => import('./drafts/drafts.module').then(m => m.DraftsModule),

            },


            {
                path: '',
                loadChildren: () => import('./my-listing/my-listing.module').then(m => m.MyListingModule),

            }
            ,
            {
                path: '',
                loadChildren: () => import('./prisma/prisma.module').then(m => m.PrismaModule),

            },


            {
                path: '',
                loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
                canActivate: [RoleGuardService]

            },


            {
                path: '',
                loadChildren: () => import('./locations-home/locations-home.module').then(m => m.LocationsHomeModule),

            },


            {
                path: '',
                loadChildren: () => import('./analytics-location/analytics-location.module').then(m => m.AnalyticsLocationModule),

            },

            {
                path: '',
                loadChildren: () => import('./analytics-geysers/analytics-geysers.module').then(m => m.AnalyticsGeysersModule),

            },

            {
                path: '',
                loadChildren: () => import('./analytics-home/analytics-home.module').then(m => m.AnalyticsHomeModule),

            },

            {
                path: '',
                loadChildren: () => import('./heat-pump/heat-pump.module').then(m => m.HeatPumpModule),

            },

            {
                path: '',
                loadChildren: () => import('./magneto-thermo/magneto-thermo.module').then(m => m.MagnetoThermoModule),

            },


            {
                path: '',
                loadChildren: () => import('./electric/electric.module').then(m => m.ElectricModule),

            },

            {
                path: '',
                loadChildren: () => import('./add-accounts/devotions.module').then(m => m.DevotionsModule),
                canActivate: [AddUsersRoleService]

            },
            {
                path: '',
                loadChildren: () => import('./userpage/user.module').then(m => m.UserModule)
            },


            {
                path: '',
                loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule),
                canActivate: [RoleGuardService]
            },

            {
                path: '',
                loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
            },

            {
                path: '',
                loadChildren: () => import('./all-users/all-users.module').then(m => m.AllUsersModule),
                canActivate: [RoleGuardService]
            },

            {
                path: '',
                loadChildren: () => import('./notices/notices.module').then(m => m.NoticesModule),

            },

            {
                path: '',
                loadChildren: () => import('./gravity-fed/gravity-fed.module').then(m => m.GravityFedModule),

            },

            {
                path: '',
                loadChildren: () => import('./organisations/org.module').then(m => m.OrganisationsModule),

            },


            {
                path: '',
                loadChildren: () => import('./organisations-stats/org-stats.module').then(m => m.OrganisationsStatsModule),

            },


            {
                path: '',
                loadChildren: () => import('./retro-fit/retro-fit.module').then(m => m.RetroFitModule),

            },


            {
                path: '',
                loadChildren: () => import('./geysers/geysers.module').then(m => m.GeysersModule),


            },

            {
                path: '',
                loadChildren: () => import('./service-forms/prv/prv.module').then(m => m.PrvModule),

            },

            {
                path: '',
                loadChildren: () => import('./service-forms/anode/anode.module').then(m => m.AnodeModule),

            },

            {
                path: '',
                loadChildren: () => import('./service-forms/vaccum/vaccum.module').then(m => m.VaccumModule),

            },

            {
                path: '',
                loadChildren: () => import('./general-certificate/general-certificate.module').then(m => m.GeneralCertificateModule)
            },


            {
                path: '',
                loadChildren: () => import('./sale-of-property/sale-of-property.module').then(m => m.SaleOfPropertyModule)
            },

            {
                path: '',
                loadChildren: () => import('./service-forms/thermosat/thermosat.module').then(m => m.ThermosatModule),

            },

            {
                path: '',
                loadChildren: () => import('./service-forms/element/element.module').then(m => m.ElementModule),

            },

            {
                path: '',
                loadChildren: () => import('./service-forms/submit-service/submit-service.module').then(m => m.SubmitServiceModule),

            },


            {
                path: '',
                loadChildren: () => import('./timeline/timeline.module').then(m => m.TimelineModule),


            },

            {
                path: '',
                loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule),


            },


            {
                path: '',
                loadChildren: () => import('./res/resources.module').then(m => m.ResourcesModule),


            },



            {
                path: '',
                loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),


            },

            {
                path: '',
                loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule),


            },
            {
                path: '',
                loadChildren: () => import('./request/request.module').then(m => m.RequestModule),


            },


            {
                path: '',
                loadChildren: () => import('./submit/submit.module').then(m => m.SubmitModule),
                //canActivate: [AdminRoleService]

            },
            {
                path: '',
                loadChildren: () => import('./solar/solar.module').then(m => m.SolarModule),


            },

            {
                path: '',
                loadChildren: () => import('./solar/solar.module').then(m => m.SolarModule),


            },


            {
                path: '',
                loadChildren: () => import('./solar-replacement/solar-replacement.module').then(m => m.SolarReplacementModule),


            },

        ],
        canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin},
        //  canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin() }


    },

    {
        path: '',
        loadChildren: () => import('./view-invoice/view-invoice.module').then(m => m.ViewInvoiceModule),

    },

    {
        path: '',
        loadChildren: () => import('./view-nuflow/view-nuflow.module').then(m => m.ViewNuflowModule),

    },


    {
        path: '',
        loadChildren: () => import('./view-sale-of-property/view-sale-of-property.module').then(m => m.ViewSaleOfPropertyModule),

    },

    {
        path: '',
        loadChildren: () => import('./view-heat-pump/view-heat-pump.module').then(m => m.ViewHeatPumpModule),

    },

    {
        path: '',
        loadChildren: () => import('./view-retro-fit/view-retro-fit.module').then(m => m.ViewRetroFitModule),

    },


    {
        path: '',
        loadChildren: () => import('./view-noc/view-noc.module').then(m => m.ViewNocModule),

    },

    {
        path: '',
        loadChildren: () => import('./view-magneto-thermo/view-magneto-thermo.module').then(m => m.ViewMagnetoThermoModule),

    },


    {
        path: '',
        loadChildren: () => import('./view-gravity-fed/view-gravity-fed.module').then(m => m.ViewGravityFedModule),

    },


    {
        path: '',
        loadChildren: () => import('./view-general-certificate/view-general-certificate.module').then(m => m.ViewGeneralCertificateModule)
    },
    {
        path: '',
        loadChildren: () => import('./view-prisma/view-prisma.module').then(m => m.ViewPrismaModule),


    },


    {
        path: '',
        loadChildren: () => import('./service-forms/view-prv/view-prv.module').then(m => m.ViewPrvModule),


    },


    {
        path: '',
        loadChildren: () => import('./service-forms/view-element/view-element.module').then(m => m.ViewElementModule),


    },


    {
        path: '',
        loadChildren: () => import('./service-forms/view-thermostat/view-thermostat.module').then(m => m.ViewThermostatModule),


    },


    {
        path: '',
        loadChildren: () => import('./service-forms/view-anode/view-anode.module').then(m => m.ViewAnodeModule),


    },


    {
        path: '',
        loadChildren: () => import('./service-forms/view-vacuum/view-vacuum.module').then(m => m.ViewVacuumModule),


    },

    {
        path: '',
        loadChildren: () => import('./view-solar/view-solar.module').then(m => m.ViewSolarModule),


    },


    {
        path: '',
        loadChildren: () => import('./view-electric/view-electric.module').then(m => m.ViewElectricModule),


    },
    {
        path: '',
        loadChildren: () => import('./media/media.module').then(m => m.MediaModule),


    },

    {
        path: '',
        loadChildren: () => import('./view-solarr/view-solarr.module').then(m => m.ViewSolarrModule),


    },

    {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: '',
            loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
        }]
    }
];
