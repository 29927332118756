

<ly-img-cropper
        [config]="myConfig"
        [(scale)]="scale"
        (minScale)="minScale = $event"
        (ready)="ready = true"
        (cleaned)="ready = false"
        (cropped)="onCropped($event)"
        (loaded)="onLoaded($event)"
        (error)="onError($event)"
>
    <span>Drag and drop image or click here to add an image</span>
</ly-img-cropper>

<div *ngIf="ready" [className]="classes.sliderContainer">
    <ly-slider
            [thumbVisible]="false"
            [min]="minScale"
            [max]="1"
            [(ngModel)]="scale"
            (input)="scale = $event.value"
            step="0.000001"></ly-slider>
</div>

<button *ngIf="ready" color="accent" (click)="cropper.crop()" ly-button>
    <ly-icon>crop</ly-icon>Crop and Submit
</button>

<!--<div><img [class]="classes.cropResult" *ngIf="croppedImage" [src]="croppedImage"></div>-->
