import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AddUsersRoleService {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


    if (localStorage.getItem('user_type') === 'admin' ||  localStorage.getItem('user_type') === 'Company') {


      return  true
    }
    else {

      this.router.navigate(['/submissions'])

      return false;
    }

  }
}
