import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {first} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CocService {
    constructor(
        public afs: AngularFirestore,
    ) {
    }


    getCount() {

        return this.afs.collection('geysers').doc('count').valueChanges().pipe(first()).toPromise();

    }

    getInvoice() {

        return this.afs.collection('invoices', ref => ref.orderBy('date', 'desc')).valueChanges({idField: 'docid'}).pipe(first()).toPromise();

    }


    getSingle(id) {
        return this.afs.collection('submissions').doc(id).valueChanges().pipe(first()).toPromise();
    }


    getSingleDraft(id) {
        return this.afs.collection('drafts').doc(id).valueChanges().pipe(first()).toPromise();
    }

    getAllSingle(id, type) {
        return this.afs.collection(type).doc(id).valueChanges().pipe(first()).toPromise();
    }

    getCompany(uid) {
        return this.afs.collection('submissions', ref => ref.where('parent', '==', uid).where('status', '==', 'approved').orderBy('sub_date', 'desc')).valueChanges({idField: 'docid'})
            .pipe(first()).toPromise();
    }


    getCocAdmin() {
        return this.afs.collection('submissions', ref => ref.where('status', '==', 'approved').orderBy('sub_date', 'desc')).valueChanges({idField: 'docid'})
            .pipe(first()).toPromise();
    }


    getCocPlumber(uid) {
        return this.afs.collection('submissions', ref => ref.where('uid', '==', uid).where('status', '==', 'approved').orderBy('sub_date', 'desc')).valueChanges({idField: 'docid'})
            .pipe(first()).toPromise();
    }


    getCocInsurance(comp) {
        return this.afs.collection('submissions', ref => ref.where('insurance', '==', comp).where('downloads', '==', true).orderBy('sub_date', 'desc')).valueChanges({idField: 'docid'})
            .pipe(first()).toPromise();
    }


    getCocManufacture(type, comp) {
        return this.afs.collection('submissions', ref => ref.where(type, '==', comp).orderBy('sub_date', 'desc')).valueChanges({idField: 'docid'})
            .pipe(first()).toPromise();
    }

    async getD() {
        return this.afs.collection('downloads', ref => ref.orderBy('date', 'desc')).valueChanges({idField: 'docid'})
            .pipe(first()).toPromise();

    }


    getMonths() {

        return this.afs.collection('months', ref => ref.orderBy('startId', 'desc')).valueChanges().pipe(first()).toPromise();
    }

    async getSourceMonthsByDate(month: any) {
        return this.afs.collection('downloads', ref => ref
            .where('date', '>', month.startId)
            .where('date', '<', month.endId)

            .orderBy('date', 'desc'))
            .valueChanges().pipe(first()).toPromise();


    }


    async getUsersMonthsByDate(month: any) {
        return this.afs.collection('users', ref => ref
            .where('id', '>', month.startId)
            .where('id', '<', month.endId)

            .orderBy('id', 'desc'))
            .valueChanges().pipe(first()).toPromise();


    }

    getUsers() {
        return this.afs.collection('users', ref => ref

            .orderBy('id', 'desc'))
            .valueChanges().pipe(first()).toPromise();    }
}
