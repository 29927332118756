export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyA2pn3vWS7fMp8GgzTKaHLdWFHC9dABebE",
    authDomain: "waccsa-e8589.firebaseapp.com",
    projectId: "waccsa-e8589",
    storageBucket: "waccsa-e8589.appspot.com",
    messagingSenderId: "974663976319",
    appId: "1:974663976319:web:8bff4faa6a2c9bbaddc64a"
  }
};


