import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  constructor() { }

  showNotification(message:string,from: any, align: any, type:any,title:any, duration? ) {
    // const type = ['', 'info', 'success', 'warning', 'danger', 'rose', 'primary'];

    const color = Math.floor((Math.random() * 6) + 1);

    var notify = $.notify({
     // icon: 'notifications',
      message: message,
      title:title
    }, {
      type: type,
      timer: duration || 150,
      allow_dismiss: true,
    })
  }







}
